import styled from "styled-components";

export const GroupContainer = styled.div`
  border: 3px solid #FFFFFF80;
  border-radius: 2.5em;
  padding: 1.25em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
`

export const GroupHeader = styled.div`
  display: flex;
  gap: 1em;
  min-height: 4em;
  flex-wrap: wrap;
  @media (min-width: 640px) {
    flex-wrap: nowrap;
  }
`

export const GroupHeaderImages = styled.div`
  display: inline-flex;
  flex-direction: row;
  /* width: clamp(auto, 2vw 6.5em); */
  /* flex: 0 1 6.5em; */
  margin-right: 1em;
  justify-content: space-between;

  div {
    width: 3em;
    height: 4em;
    aspect-ratio: 1;
    border-radius: 5em;
    img {
      clip-path: circle();
      width: 4em;
      height: 4em;
      background-size: cover;
      position: relative;
      transition: scale .3s cubic-bezier(0.47,-0.02, 0.09, 1.05);

      :hover {
        z-index: 2;
        scale: 1.3;
      }
    }
  }
`

export const GroupHeaderTitle = styled.h2`
  margin: 0;
  display: flex;
  flex-direction: column;
  span {
    width: 100%;
  }
`

export const GroupDetailsContainer = styled.div`
`

export const GroupDetailsType = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: .5em;
`

export const GroupType = styled.div`
  width: 6em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 3px solid #fff;
  border-radius: 50em;
  padding: 0 0.3em;
  text-wrap: pretty;
  font-size: .8em;
  cursor: default;
  background-color: ${({bgColor = "transparent"}) => bgColor };
  transition: scale .3s cubic-bezier(0.47,-0.02, 0.09, 1.05);

  :hover {
    scale: 1.2;
  }
`

export const GroupDetails = styled.div`
`

export const GroupDetailsDateAndExtraContainer = styled.div`
`

export const GroupDetailsDate = styled.div`
`

export const GroupDetailsPlace = styled.div`
`

export const AdditionalInfoContainer = styled.div`
`

export  const GroupDetailsExtraInfo = styled.div`
`

export const GroupHosts = styled.div`
`

export const GroupFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const GroupJoinContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: .2em;
`
export const GroupJoinButton = styled.a`
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  border-radius: 40em;
  border: 3px solid #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5em 3em;
  transition: all .3s cubic-bezier(0.47,-0.02, 0.09, 1.05);

  :hover,:focus {
    cursor: pointer;
    background: #fff;
    color: #80CCD8;
  }

  &:active {
    background: #fff;
    color: #80CCD8B2;
  }
`
export const GroupJoinButtonArrow = styled.div`
`

export const GroupAvailableSeats = styled.div`
  font-size: 0.75em;
  text-align: center;
`
