import React, {useEffect, useState} from 'react'
import {
  GroupContainer,
  GroupHeader,
  GroupHeaderTitle,
  GroupHeaderImages,
  GroupFooter,
  AdditionalInfoContainer,
  GroupAvailableSeats,
  GroupDetails,
  GroupDetailsContainer, GroupDetailsDate, GroupDetailsDateAndExtraContainer, GroupDetailsExtraInfo, GroupDetailsPlace,
  GroupDetailsType,
  GroupJoinButton, 
  GroupJoinContainer,
  GroupType
} from "./styles";
import * as PropTypes from "prop-types";
import {graphql, StaticQuery} from "gatsby";
import axios from "axios";

GroupHeader.propTypes = {children: PropTypes.node};
export const Group = ({group}) => {

  const [hosts, setHosts] = useState([]);
  const [hostImages, setHostImages] = useState([]);
  const [place, setPlace] = useState();
  const [targets, setTargets] = useState([]);
  const [availableSeats, setAvailableSeats] = useState('');


  useEffect(() => {
    axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/users`).then((data) => {
      if (data?.data) {
        setHosts(data.data);
      }
    });

    axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/places`).then((data) => {
      if (data?.data) {
        setPlace(data.data?.name);
      }
    })
    axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/targets`).then((data) => {
      if (data?.data) {
        setTargets(data.data);
      }
    })
    axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/participants`).then((data) => {
      if (data?.data) {
        setAvailableSeats(data.data?.count);
      }
    })
  }, [])

  useEffect(() => {
    if (hosts) {
      const newHostImages = [];
      const promises = [];

      hosts.forEach((host) => {
        promises.push(axios.get(`https://www.grupy-radosci.pl/api/v1/groups/${group.id}/users/${host.id}`));
      });

      Promise.all(promises).then(function (results) {
        results.forEach(function (response) {
          const image = response.data.url;
          !image.includes('transparent') && newHostImages.push(image);
        });

        setHostImages(newHostImages);
      });
    }
  }, [hosts])

  const dayOfWeek = {
      monday: 'Poniedziałek',
      tuesday: 'Wtorek',
      wednesday: 'Środa',
      thursday: 'Czwartek',
      friday: 'Piątek',
      saturday: 'Sobota',
      sunday: 'Niedziela'
    }[group.day_of_week]

  return (
    <StaticQuery
      query={graphql`
        query GroupQuery {
          arrow: file(relativePath: { eq: "back-arrow.png" }) {
            childImageSharp {
              fluid(maxWidth: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <GroupContainer>
          <GroupHeader>
            { !!hostImages.length &&
              <GroupHeaderImages>
                {hostImages && hostImages.map((image, index) => <div><img key={index} src={image} alt=''/></div>)}
              </GroupHeaderImages>
            }
            <GroupHeaderTitle>
            {hosts.map(({first_name, last_name}) => (
              <span>
                {`${first_name} ${last_name}`}
              </span>
            ))}
            </GroupHeaderTitle>
          </GroupHeader>
          <GroupDetailsContainer>
            
            <GroupDetails>
              <GroupDetailsDateAndExtraContainer>
                <GroupDetailsDate>
                  {dayOfWeek} - {group.hour}
                </GroupDetailsDate>
                <GroupDetailsPlace>
                  {place}
                </GroupDetailsPlace>
              </GroupDetailsDateAndExtraContainer>
              {group.additionalInfo && (
                <AdditionalInfoContainer>
                  <span>|</span>
                  <GroupDetailsExtraInfo>
                    {group.additionalInfo}
                  </GroupDetailsExtraInfo>
                </AdditionalInfoContainer>
              )}
            </GroupDetails>
          </GroupDetailsContainer>
          <GroupFooter>
            <GroupDetailsType>
              {targets?.name && targets?.name.map((type, index) => (
                <GroupType bgColor={targets.color[index]}>
                  {type}
                </GroupType>
              ))}
            </GroupDetailsType>
            <GroupJoinContainer>
              {availableSeats && (availableSeats !== '0/10') ? (
                <GroupJoinButton href={`/formularz/${group.id}`} >
                  Dołącz
                </GroupJoinButton>
              ) : null}
              <GroupAvailableSeats>
                {availableSeats && availableSeats !== '0/10' ? (
                  <>
                    Dostępnych miejsc: {availableSeats}
                  </>
                ) : <div>Aktualnie brak dostępnych miejsc</div>}
              </GroupAvailableSeats>
            </GroupJoinContainer>
          </GroupFooter>
        </GroupContainer>
      )}
    />
  )
};
