import styled from "styled-components";

export const R = styled.div`
  width: clamp(2em, 3.5vw, 4em);
  margin: 0 auto;
`

export const HeaderH1 = styled.h1`
  font-weight: 700;
  font-size: clamp(3em, 7vw, 8em);
  text-align: center;
  margin: 0.4em 0 0 0;
`;

export const HeaderH3 = styled.h3`
  font-family: ivypresto-display, serif;
  font-style: italic;
  font-weight: 300;
  margin: 0;
  z-index: 10;
  position: relative;
  font-size: clamp(1.5em, 3.5vw, 3.5em);
  text-align: center;
  text-shadow: 1px 1px 1px #F36F4EB2;
`

export const FindGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1em;
  gap: 2em;
  max-width: 1280px;

  @media (min-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0;
    padding: 0 1.5em;
    margin: 0 auto;
  }
`

export const FindGroupImageContainer = styled.div`
  img {
    object-fit: contain !important;
  }

  flex: 0 1 50%;
  width: 100%;
  max-width: 41em;
  position: relative;
  overflow: hidden;

  @media (min-width: 1280px) {
    scale: 1.1;
  }
`

export const GroupFoo = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 40em;

  @media (min-width: 1024px) {
    flex-direction: column-reverse;
    flex: 1 1 50%;
    gap: 4em;
  }
`

export const FindGroupButton = styled.button`
  z-index: 10;
  top: -10em;
  position: absolute;
  align-self: center;
  width: clamp(16em, 80%, 20em);
  border: 3px solid #fff;
  border-radius: 4em;
  background: #F36F4EB2;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.25em;
  font-weight: bold;
  letter-spacing: 1px;
  padding: .6em 0;
  transition: all .3s cubic-bezier(0.47,-0.02, 0.09, 1.05);

  @media (min-width: 1024px) {
    position: initial;
    align-self: auto;
  }

  &:hover,:focus {
    cursor: pointer;
    background: #fff;
    color: #F36F4E;
  }

  &:active {
    background: #fff;
    color: #F36F4EB2;
  }
`

export const FindGroupDescription = styled.p`
  font-family: ivypresto-display, serif;
  font-weight:300;
  text-align: justify;
  letter-spacing: 1px;
  margin: 0;
  font-size: clamp(1.4em, 2.5vw, 1.6em);
`

export const FindGroupStepsContainer = styled.div`
  display: grid;
  gap: 2em;
  max-width: 40em;
  margin-bottom: 4em;

  @media (min-width: 1024px) {
    margin-bottom: 0;
    flex: 1 1 auto;
    max-width: none;
    grid-template-columns: repeat(3, 1fr);
  }
`

export const StepContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr;
`

export const FindGroupStepTitle = styled.pre`
  font-size: clamp(1.8em, 7vw, 2.5em);
  font-weight: 700;
  letter-spacing: 1px;
  font-family: inherit;
  margin: 0 0 0.25em 0;
  width: 100%;
  place-self: self-end;
`

export const FindGroupStepText = styled.div`
  font-size: 1.25em;
  font-weight: 300;
  text-align: justify;
`

