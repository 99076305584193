import React from 'react'
import {
  FindGroupDescription,
  FindGroupImageContainer,
  FindGroupStepsContainer,
  StepContainer,
  FindGroupStepTitle,
  FindGroupStepText,
  GroupFoo,
  FindGroupButton,
  HeaderH1,
  HeaderH3,
  R
} from "./styles";
import {FindGroupContainer} from "./styles";
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";

export const GroupsIntro = ({ resultRef }) => {

  const handleClick = () => resultRef.current?.scrollIntoView({behavior: 'smooth'});

  const steps = [
    {
      title: 'Wybierz grupę',
      description: 'Istnieją różne rodzaje grup zależnie od grupy wiekowej, płci, relacji, lokalizacji i terminu spotkań. Skorzystaj z filtra i wybierz grupę, która jest odpowiednia dla ciebie.'
    },
    {
      title: 'Zapisz się',
      description: 'Kliknij w przycisk, wypełnij formularz i czekaj na maila zwrotnego ze szczegółowymi informacjami dotyczącymi spotkania. Zapisy możliwe do 24 godzin przed spotkaniem.'
    },
    {
      title: 'Przyjdź na chwilę\nlub zostań na dłużej',
      description: 'Docelowo warto stać się częścią konkretnej grupy na dany sezon, jednak nie musisz robić tego od razu. Jeśli chcesz, zapisz się na pojedyncze spotkanie. Daj sobie czas na poszukiwania. W każdej chwili możesz zacząć szukać innej lub zrezygnować.'
    }
  ]
  
  return (
    <StaticQuery
      query={graphql`
        query FindGroupQuery {
          heroImg: file(relativePath: { eq: "collage.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          icon: file(relativePath: { eq: "icon.png" }) {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <R>
            <Img fluid={data.icon.childImageSharp.fluid} />
          </R>
          <HeaderH1>
            GRUPY RADOŚCI
          </HeaderH1>
          <HeaderH3>
            Łączymy ludzi, dzieląc życie
          </HeaderH3>
          <FindGroupContainer>
            <FindGroupImageContainer>
              <Img fluid={data.heroImg.childImageSharp.fluid} />
            </FindGroupImageContainer>
            <GroupFoo>
              <FindGroupButton onClick={handleClick}>znajdź grupę dla siebie</FindGroupButton>
              <FindGroupDescription>
                Grupy Radości to spotkania do 10 osób, które odbywają się na terenie Trójmiasta lub online. Ludzie spędzają razem czas, aby omówić konkretny temat duchowy, porozmawiać o życiu, wspólnie modlić i wspierać. 
                <br /><br />
                Spotkania są organizowane regularnie co tydzień lub dwa, w każdym z trzech sezonów: wrzesień - listopad, styczeń - marzec, maj - czerwiec.</FindGroupDescription>
            </GroupFoo>
            <FindGroupStepsContainer>
              {steps.map(({title, description}) => (
                <StepContainer>
                    <FindGroupStepTitle>{title}</FindGroupStepTitle>
                    <FindGroupStepText>{description}</FindGroupStepText>
                </StepContainer>
              ))}
              </FindGroupStepsContainer>
          </FindGroupContainer>
        </>
      )
      }
    />
  )
}
