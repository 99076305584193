import * as React from "react";
import { useRef } from "react";
import {Helmet} from "react-helmet";
import "normalize.css";
import "../styles/main.css";
import styled from "styled-components";
import {Group} from "../components/GroupsPage/Group";
import {GroupsIntro} from "../components/GroupsPage/GroupsIntro";
import {useEffect, useState} from "react";
import axios from "axios";

const PageBackground = styled.main`
  background-color: #F36F4E;
  background: linear-gradient(90deg, #e26445 0%, #F36F4E 25%, #F36F4E 75%, #e26445 100%);
  padding: 1.5em 0 0;
  position: relative;
`
const GroupsContainer = styled.div`
  background: #80CCD8;
  padding: 5em 1.5em;
`

const GroupsHeader = styled.h1`
  font-size: clamp(2.75em, 5vw, 4.5em);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2em;
`

const GroupsList = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  gap: 1.25em;
  grid: auto / 1fr;
  @media (min-width: 640px) {
    grid: auto / repeat(auto-fit, minmax(24em, 1fr));
    grid-auto-flow: row dense;
  }
`



export const NotFoundMessageContainer = styled.div`
`

export const NotFoundMessageTitle = styled.div`
`

export const NotFoundMessageText = styled.div`
`

const GroupsPage = () => {
  // const groups = data.allContentfulGroups.nodes;
  // const days = data.allContentfulDays.nodes;
  // const types = data.allContentfulTypes.nodes;
  // const places = data.allContentfulPlaces.nodes;

  const [groups, setGroups] = useState([]);
  const resultRef = useRef(null);
  useEffect(() => {
    axios.get('https://www.grupy-radosci.pl/api/v1/groups').then((data) => {
      if (data?.data) {
        console.log(data.data)
        setGroups(data.data);
      }
    });
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>Grupy Radości - Kościół Radość</title>
        <link rel="stylesheet" href="https://use.typekit.net/rjh5hkz.css"/>
      </Helmet>
      <PageBackground>
          <GroupsIntro resultRef={resultRef}/>
          <GroupsContainer ref={resultRef}>
            <GroupsHeader>Znajdź grupę dla siebie:</GroupsHeader>
            <GroupsList>
              {groups && groups.map(group => 
                <Group key={group.id} group={group} data={{}}/>
                )}
            </GroupsList>
          </GroupsContainer>
      </PageBackground>
    </React.Fragment>
  );
};

export default GroupsPage;
